import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { showKeyboard } from '../../actions/overlay.action';
import App from '../../components/app/app';
import { State } from '../../reducers/store';

const mapStateToProps = (state: State) => ({
  showOnyoIcon: state.overlayState.showOnyoIcon,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onIconClick: bindActionCreators(showKeyboard, dispatch),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
