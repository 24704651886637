import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendTelephoneNumber } from '../../actions/common.action';
import { showOnyoIcon, telephoneAppend, telephoneBackspace } from '../../actions/overlay.action';
import { Keyboard } from '../../components/keyboard/keyboard';
import { State } from '../../reducers/store';

const mapStateToProps = (state: State) => ({
  telephone: state.overlayState.formattedTelephone,
  isTelephoneValid: state.overlayState.isTelephoneValid,
});

const mapDispathToProps = (dispatch: Dispatch) => ({
  onKeyPress: bindActionCreators(telephoneAppend, dispatch),
  onClosePress: bindActionCreators(showOnyoIcon, dispatch),
  onSubmitPress: bindActionCreators(sendTelephoneNumber, dispatch),
  onBackspacePress: bindActionCreators(telephoneBackspace, dispatch),
});

const KeyboardContainer = connect(mapStateToProps, mapDispathToProps)(Keyboard);
export default KeyboardContainer;
