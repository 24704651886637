// tslint:disable:no-submodule-imports
import { Action } from 'redux';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { genericAction } from '../../actions/common.action';
import { State } from '../../reducers/store';
import { OverlayActionTypes, ShowKeyboardAction, ShowOnyoIconAction } from './../../actions/overlay.action';
import { ASTI_MSG_SHOW_KEYBOARD, ASTI_MSG_SHOW_ONYO_ICON } from './../../astilectron/astilectron-message';

export const showOnyoIconEpic = (
  action$: ActionsObservable<Action>,
  store$: StateObservable<State>,
  {
    ajax,
    astiService,
    scheduler,
  }) =>
  action$.pipe(
    ofType<ShowOnyoIconAction>(OverlayActionTypes.SHOW_ONYO_ICON_ACTION),
    map(() => {
      astiService.sendMessage({ type: ASTI_MSG_SHOW_ONYO_ICON, payload: undefined });
      return genericAction();
    }),
  );

export const showKeyboardEpic = (
  action$: ActionsObservable<Action>,
  store$: StateObservable<State>,
  {
    ajax,
    astiService,
    scheduler,
  }) =>
  action$.pipe(
    ofType<ShowKeyboardAction>(OverlayActionTypes.SHOW_KEYBOARD_ACTION),
    map(() => {
      astiService.sendMessage({ type: ASTI_MSG_SHOW_KEYBOARD, payload: undefined });
      return genericAction();
    }),
  );
