import { Action } from 'redux';

/**
 * Actions that are shared by more than one reducer.
 */
export enum CommonActionTypes {
  SEND_TELEPHONE_NUMBER_ACTION = 'send-telephone-number',
  GENERIC_ACTION = 'generic-action',
}

export interface SendTelephoneNumberAction extends Action {
  type: CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION;
}

/**
 * Sends the telephone number to the backend.
 */
export function sendTelephoneNumber(): SendTelephoneNumberAction {
  return {
    type: CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION,
  };
}

/**
 * Generic action that is not processed by any reducer. Is here to return an action so that the Epics don't fail.
 */
export interface GenericAction extends Action {
  type: CommonActionTypes.GENERIC_ACTION;
}

export function genericAction(): GenericAction {
  return {
    type: CommonActionTypes.GENERIC_ACTION,
  };
}
