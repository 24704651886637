import { CommonActionTypes } from './../../actions/common.action';
import { OverlayActions, OverlayActionTypes } from './../../actions/overlay.action';

export interface OverlayState {
  showOnyoIcon: boolean;
  telephone: string;
  formattedTelephone: string;
  isTelephoneValid: boolean;
}

export const initialState: OverlayState = {
  showOnyoIcon: true,
  telephone: '',
  formattedTelephone: '',
  isTelephoneValid: false,
};

export function overlayReducer(state: OverlayState = initialState, action: OverlayActions): OverlayState {
  let num: string;
  let formattedNum: string;
  let isTelephoneValid: boolean;

  switch (action.type) {
    case OverlayActionTypes.SHOW_ONYO_ICON_ACTION:
      return { ...state, showOnyoIcon: true };

    case CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION:
      // Show the Onyo icon if the telephone is valid (we are going to send it to the backend).
      if (state.isTelephoneValid) {
        return { ...state, showOnyoIcon: true };
      }

      return state;

    case OverlayActionTypes.SHOW_KEYBOARD_ACTION:
      return { ...state, showOnyoIcon: false, telephone: '', formattedTelephone: '', isTelephoneValid: false };

    case OverlayActionTypes.TELEPHONE_APPEND_ACTION:
      // Allow only 11 digits.
      if (state.telephone.length >= 11) {
        isTelephoneValid = true;
        return {...state, isTelephoneValid };
      }

      num = filterNonNumericalChars(state.telephone.concat(action.payload.num));
      formattedNum = formatCellphone(num);
      if (num.length === 11) {
        isTelephoneValid = true;
      }
      else {
        isTelephoneValid = false;
      }

      return { ...state, telephone: num, formattedTelephone: formattedNum, isTelephoneValid };

    case OverlayActionTypes.TELEPHONE_BACKSPACE_ACTION:
      num = state.telephone.slice(0, -1);
      formattedNum = formatCellphone(num);
      return { ...state, telephone: num, formattedTelephone: formattedNum, isTelephoneValid: false };

    default:
      return state;
  }
}

/**
 * Formats a number string into the cellphone format: "(XX) XXXXX-XXXX"
 * Note: all non numeric characters will be filtered out of the input string.
 */
function formatCellphone(cellphoneNumber: string): string {
  // Begin formatting from 3 charactes and above, to make sure we can remove the '()' from the prefix.
  if (cellphoneNumber.length < 3) {
    return cellphoneNumber;
  }

  // Allow only 11 digits.
  if (cellphoneNumber.length > 11) {
    cellphoneNumber = cellphoneNumber.substring(0, 11);
  }

  const ddd = cellphoneNumber.substring(0, 2);
  const prefix = cellphoneNumber.substring(2, 7);
  const sufix = cellphoneNumber.substring(7);

  let formattedValue: string;
  if (sufix === '') {
    formattedValue = `(${ddd}) ${prefix}`;
  }
  else {
    formattedValue = `(${ddd}) ${prefix}-${sufix}`;
  }

  return formattedValue;
}

/**
 * Remove all non numerical chars from string.
 */
function filterNonNumericalChars(str: string): string {
  return str.replace(new RegExp('\\D', 'g'), ''); // get only the numbers from the input string.
}
