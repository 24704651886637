import { Action } from 'redux';
import { SendTelephoneNumberAction } from './common.action';

export enum SGMActionTypes {
  SEND_TELEPHONE_NUMBER_SUCCESS_ACTION = 'send-telephone-number-success',
  SEND_TELEPHONE_NUMBER_FAIL_ACTION = 'send-telephone-number-fail',
}

export interface SendTelephoneNumberSuccessAction extends Action {
  type: SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION;
}

export function sendTelephoneNumberSuccess(): SendTelephoneNumberSuccessAction {
  return {
    type: SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION,
  };
}

export interface SendTelephoneNumberFailAction extends Action {
  type: SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION;
  payload: {
    error: Error;
  };
}

export function sendTelephoneNumberFail(error: Error): SendTelephoneNumberFailAction {
  return {
    type: SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION,
    payload: { error },
  };
}

export type SGMActions = SendTelephoneNumberAction |
                         SendTelephoneNumberSuccessAction |
                         SendTelephoneNumberFailAction;
