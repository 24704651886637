import * as Sentry from '@sentry/browser';

import { Store } from '..';
import { setCompany } from '../actions/company.action';
import { Company } from './../common/company.model';
import { ASTI_MSG_SET_COMPANY, AstilectronMessage } from './astilectron-message';

// This is the global window.astilectron object that allows communication between
// render and main process. It needs to be declared like this to avoid reference
// errors.
declare var astilectron: any;

/**
 * Handles Astilectron events
 */
export class AstilectronService {
  private isReady: boolean;

  constructor() {
    this.isReady = false;
  }

  /**
   * Initializes class and registers event listeners, once the astilectron is ready.
   * Must be called before usage.
   */
  public init() {
    document.addEventListener('astilectron-ready', (e: Event) => {
      this.isReady = true;

      astilectron.onMessage(this.handleIncomingMessages.bind(this));
    });
  }

  /**
   * Send a message to the GO connector service, without waiting for a reply.
   */
  public sendMessage(message: AstilectronMessage) {
    if (typeof astilectron !== 'undefined') {
      astilectron.sendMessage(message);
    }
    else {
      const msg = `Astilectron undefined. Cannot send message: ${message.type}`;
      console.warn(msg);
      Sentry.captureEvent({
        message: msg,
        level: Sentry.Severity.Warning,
        extra: message,
      });
    }
  }

  private handleIncomingMessages(message: AstilectronMessage) {
    switch (message.type) {
      case ASTI_MSG_SET_COMPANY:
        const comp = new Company();
        comp.name = message.payload.companyName;
        comp.token = message.payload.apiToken;
        Store.dispatch(setCompany(comp));
        break;

      default:
        const msg = `Unknown astilectron message: ${message.type}`;
        console.warn(msg);
        Sentry.captureEvent({
          message: msg,
          level: Sentry.Severity.Warning,
          extra: message,
        });
        break;
    }
  }
}
