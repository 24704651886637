import * as Sentry from '@sentry/browser';
import { CompanyActions, CompanyActionTypes } from '../../actions/company.action';
import { Company } from '../../common/company.model';

export interface CompanyState {
  company: Company;
}

export const initialState: CompanyState = {
  company: new Company(),
};

export function companyReducer(state: CompanyState = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.SET_COMPANY_ACTION:
      configureSentryScope(action.payload.company);
      return { ...state, company: action.payload.company };

    default:
      return state;
  }
}

function configureSentryScope(company: Company) {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setUser({ username: company.name });
  });
}
