import { ASTI_MSG_SHOW_ONYO_ICON } from './../../astilectron/astilectron-message';
// tslint:disable:no-submodule-imports

import * as Sentry from '@sentry/browser';
import { Action } from 'redux';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, timeout } from 'rxjs/operators';

import { SendTelephoneNumberAction } from '../../actions/common.action';
import { sendTelephoneNumberFail, sendTelephoneNumberSuccess } from '../../actions/sgm.action';
import { CommonActionTypes } from './../../actions/common.action';
import { State } from './../../reducers/store';

const url = `${process.env.REACT_APP_API_URL}/v1/connector/sms_temporary_user`;
const timeoutValue = Number(process.env.REACT_APP_DEFAULT_TIMEOUT);

/**
 * Try to send the telephone number or throw an error if no company is selected
 */
export const sendTelephoneEpic = (action$: ActionsObservable<Action>, store$: StateObservable<State>, { ajax, astiService, scheduler }) =>
  merge(
    sendTelephoneEpicInvalidNumber(action$, store$, { ajax, astiService, scheduler }),
    sendTelephoneEpicWithToken(action$, store$, { ajax, astiService, scheduler }),
    sendTelephoneEpicWithoutToken(action$, store$, { ajax, astiService, scheduler }),
  );

  /**
   * Throw an error if the telephone number is invalid (less or more than 11 digits)
   */
const sendTelephoneEpicInvalidNumber =
(action$: ActionsObservable<Action>, store$: StateObservable<State>, { ajax, astiService, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() => !store$.value.overlayState.isTelephoneValid),
    map(() => {
      const errorMsg = `Invalid telephone number: ${store$.value.overlayState.telephone}`;
      const error = new Error(errorMsg);
      error.name = 'invalid-telephone-number';
      Sentry.captureEvent({
        message: errorMsg,
        level: Sentry.Severity.Warning,
        extra: {
          error,
        },
      });

      console.warn(error);
      return sendTelephoneNumberFail(error);
    }),
  );

/**
 * Throw an error if we don't have a token selected.
 */
const sendTelephoneEpicWithoutToken =
(action$: ActionsObservable<Action>, store$: StateObservable<State>, { ajax, astiService, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() =>
      store$.value.overlayState.isTelephoneValid &&
      store$.value.companyState.company.token === '',
    ),
    map(() => {
      const errorMsg = 'No company selected';
      const error = new Error(errorMsg);
      error.name = 'no-company-selected';
      Sentry.captureEvent({
        message: errorMsg,
        level: Sentry.Severity.Warning,
        extra: {
          error,
        },
      });

      console.warn(error);
      return sendTelephoneNumberFail(error);
    }),
  );

/**
 * Try to send the telephone number if we have a company and a token selected.
 */
const sendTelephoneEpicWithToken =
(action$: ActionsObservable<Action>, store$: StateObservable<State>, { ajax, astiService, scheduler }) =>
  action$.pipe(
    ofType<SendTelephoneNumberAction>(CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION),
    filter(() =>
      store$.value.overlayState.isTelephoneValid &&
      store$.value.companyState.company.token !== '',
    ),
    mergeMap((action: SendTelephoneNumberAction) => {
      // Inform the connector service (in GO) that the screen size changed
      astiService.sendMessage({ type: ASTI_MSG_SHOW_ONYO_ICON, payload: undefined });

      return ajax({
        url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': store$.value.companyState.company.token,
        },
        body: { telephone: store$.value.overlayState.telephone },
      }).pipe(
        timeout(timeoutValue, scheduler),
        map(() => sendTelephoneNumberSuccess()),
        catchError((error: Error) => {
          const errorMsg = 'Error sending telephone number';
          Sentry.captureEvent({
            message: errorMsg,
            level: Sentry.Severity.Error,
            extra: {
              error,
            },
          });

          console.error(errorMsg, error);
          return of(sendTelephoneNumberFail(error));
        }),
      );
    }),
  );
