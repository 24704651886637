import { Action } from 'redux';

import { Company } from './../common/company.model';

export enum CompanyActionTypes {
  SET_COMPANY_ACTION = 'set-company',
}

/**
 * Set a company
 */
export interface SetCompanyAction extends Action {
  type: CompanyActionTypes;
  payload: {
    company: Company;
  };
}

export function setCompany(company: Company): SetCompanyAction {
  return {
    type: CompanyActionTypes.SET_COMPANY_ACTION,
    payload: { company },
  };
}

export type CompanyActions = SetCompanyAction;
