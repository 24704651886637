import { Action } from 'redux';

import { SendTelephoneNumberAction } from './common.action';

export enum OverlayActionTypes {
  SHOW_ONYO_ICON_ACTION = 'show-onyo-icon',
  SHOW_KEYBOARD_ACTION = 'show-keyboard',
  TELEPHONE_APPEND_ACTION = 'telephone-append',
  TELEPHONE_BACKSPACE_ACTION = 'telephone-backspace',
}

export interface ShowOnyoIconAction extends Action {
  type: OverlayActionTypes.SHOW_ONYO_ICON_ACTION;
}

export function showOnyoIcon(): ShowOnyoIconAction {
  return {
    type: OverlayActionTypes.SHOW_ONYO_ICON_ACTION,
   };
}

export interface ShowKeyboardAction extends Action {
  type: OverlayActionTypes.SHOW_KEYBOARD_ACTION;
}

export function showKeyboard(): ShowKeyboardAction {
  return {
    type: OverlayActionTypes.SHOW_KEYBOARD_ACTION,
  };
}

export interface TelephoneAppendAction {
  type: OverlayActionTypes.TELEPHONE_APPEND_ACTION;
  payload: {
    num: string;
   };
}

export function telephoneAppend(num: string): TelephoneAppendAction {
  return {
    type: OverlayActionTypes.TELEPHONE_APPEND_ACTION,
    payload: { num },
  };
}

export interface TelephoneBackspaceAction {
  type: OverlayActionTypes.TELEPHONE_BACKSPACE_ACTION;
}

export function telephoneBackspace(): TelephoneBackspaceAction {
  return {
    type: OverlayActionTypes.TELEPHONE_BACKSPACE_ACTION,
  };
}

export type OverlayActions = ShowOnyoIconAction |
                             ShowKeyboardAction |
                             SendTelephoneNumberAction |
                             TelephoneAppendAction |
                             TelephoneBackspaceAction;
