import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { AstilectronService } from './astilectron/astilectron-service';
import { AppContainer } from './containers/app-container/app.container';
import initStore from './reducers/store';
import { configSentry } from './sentry';
import * as serviceWorker from './serviceWorker';

configSentry();
export const astilectronService = new AstilectronService();
astilectronService.init();
export const Store = initStore();

ReactDOM.render(
  <Provider store={Store}>
    <AppContainer />
  </Provider>
  , document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
