import './keyboard.scss';

import { faBackspace, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { KeyboardKey } from '../keyboard-key/keyboard-key';

export interface KeyboardProps {
  telephone: string;
  isTelephoneValid: boolean;
  onKeyPress: (value: string) => void;
  onSubmitPress: () => void;
  onClosePress: () => void;
  onBackspacePress: () => void;
}

export function Keyboard(props: KeyboardProps) {
  return (
    <div className='keyboard-grid d-flex flex-column p-2'>
      <div className='py-1'>
        <InputGroup size='sm'>
          <Input
            placeholder='Celular com DDD'
            type='tel'
            name='cellphoneInput'
            className={'cellphone-input ' + (props.isTelephoneValid ? 'cellphone-input-valid' : 'cellphone-input-invalid')}
            value={props.telephone}
            required={true}
            disabled={true}
          />
          <InputGroupAddon addonType='append'>
            <Button className='backspace-button' onClick={props.onBackspacePress}>
              <FontAwesomeIcon icon={faBackspace} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <div className='d-flex justify-content-around py-1'>
        <div className='px-1'><KeyboardKey value='1' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='2' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='3' onKeyPress={props.onKeyPress} /></div>
      </div>
      <div className='d-flex justify-content-around py-1'>
        <div className='px-1'><KeyboardKey value='4' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='5' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='6' onKeyPress={props.onKeyPress} /></div>
      </div>
      <div className='d-flex justify-content-around py-1'>
        <div className='px-1'><KeyboardKey value='7' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='8' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='9' onKeyPress={props.onKeyPress} /></div>
      </div>
      <div className='d-flex justify-content-around py-1'>
        <div className='px-1'><KeyboardKey value='' icon={faTimes} onKeyPress={props.onClosePress} /></div>
        <div className='px-1'><KeyboardKey value='0' onKeyPress={props.onKeyPress} /></div>
        <div className='px-1'><KeyboardKey value='' icon={faCheck} onKeyPress={props.onSubmitPress} /></div>
      </div>
    </div>
  );
}
