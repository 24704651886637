import './app.scss';

import * as React from 'react';
import { Button } from 'reactstrap';

import KeyboardContainer from '../../containers/keyboard-container/keyboard.container';

export interface AppProps {
  showOnyoIcon: boolean;
  onIconClick: () => void;
}

export default class App extends React.PureComponent<AppProps, any> {
  public render(): JSX.Element {
    return (
      <div className='app d-flex align-items-center justify-content-center'>
        {this.showIconOrInput()}
      </div>
    );
  }

  private showIconOrInput(): JSX.Element {
    if (this.props.showOnyoIcon) {
      return (
        <div className='onyo-logo-background d-flex align-items-center justify-content-center'>
          <div className='p-2'>
            <img className='onyo-logo' src='onyo-logo.png' />
          </div>
          <div className='p-2'>
            <Button className='onyo-button' onClick={this.props.onIconClick}>Convidar!</Button>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className='keyboard'>
          <KeyboardContainer />
        </div>
      );
    }
  }
}
