// tslint:disable:no-submodule-imports
import {
  REQ_STATUS_FAIL,
  REQ_STATUS_PROCESSING,
  REQ_STATUS_SUCCESS,
  REQ_STATUS_UNDEFINED,
} from '../../common/request-status';
import { CommonActionTypes } from './../../actions/common.action';
import { SGMActions, SGMActionTypes } from './../../actions/sgm.action';

export interface SGMState {
  error?: Error;
  status: number;
}

export const initialState: SGMState = {
  error: undefined,
  status: REQ_STATUS_UNDEFINED,
};

export function sgmReducer(state: SGMState = initialState, action: SGMActions): SGMState {
  switch (action.type) {
    case CommonActionTypes.SEND_TELEPHONE_NUMBER_ACTION:
      return { ...state, error: undefined, status: REQ_STATUS_PROCESSING };

    case SGMActionTypes.SEND_TELEPHONE_NUMBER_SUCCESS_ACTION:
      return { ...state, status: REQ_STATUS_SUCCESS };

    case SGMActionTypes.SEND_TELEPHONE_NUMBER_FAIL_ACTION:
      return { ...state, status: REQ_STATUS_FAIL, error: action.payload.error };

    default:
      return state;
  }
}
