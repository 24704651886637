import { Action, applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { ajax } from 'rxjs/ajax';

import { astilectronService } from './..';
import { epics } from './../epics/epics';
import * as companyReducer from './company-reducer/company.reducer';
import * as overalyReducer from './overlay-reducer/overlay.reducer';
import * as sgmReducer from './sgm-reducer/sgm.reducer';

export interface State {
  overlayState: overalyReducer.OverlayState;
  sgmState: sgmReducer.SGMState;
  companyState: companyReducer.CompanyState;
}

export const initialState: State = {
  overlayState: overalyReducer.initialState,
  sgmState: sgmReducer.initialState,
  companyState: companyReducer.initialState,
};

export const reducers = combineReducers<State>({
  overlayState: overalyReducer.overlayReducer,
  sgmState: sgmReducer.sgmReducer,
  companyState: companyReducer.companyReducer,
});

export default function initStore(): Store<State, Action> {
  const epicMiddleware = createEpicMiddleware<any, any, any>({
    dependencies: { ajax, astiService: astilectronService, scheduler: undefined },
  });

  // Devtools must be removed for production build
  let store;
  if (process.env.REACT_APP_ENV === 'production') {
    store = createStore(
      reducers,
      initialState,
      applyMiddleware(epicMiddleware),
    );
  }
  else {
    store = createStore(
      reducers,
      initialState,
      composeWithDevTools(applyMiddleware(epicMiddleware)),
    );
  }

  epicMiddleware.run(epics);

  return store;
}
