import { combineEpics } from 'redux-observable';

import { showKeyboardEpic, showOnyoIconEpic } from './overlay-epic/overlay.epic';
import { sendTelephoneEpic } from './sgm-epic/sgm.epic';

export const epics = combineEpics(
  sendTelephoneEpic,
  showOnyoIconEpic,
  showKeyboardEpic,
);
